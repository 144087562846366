@charset "utf-8";
/* CSS Document */

/* +++++++++++++++++++++++++++++++++++++++ */
/* + footer ++++++++++++++++++++++++++++++++ */
/* +++++++++++++++++++++++++++++++++++++++ */
#footer_01{
	background:#51300e;

}

#footer_01 .inner_01{
	width:1200px;
	display:block;
	padding:40px 0;
}


#footer_01 ul{width:600px; margin:0 auto;}
#footer_01 li{ 
	color:#fff;
	padding:0 10px;
}
#footer_01 a{ color:#fff; text-decoration:none;}


